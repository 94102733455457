import React from 'react';
import Scene from './scene/Scene';
import { ethers } from "ethers";


function App() {
  return (
    <Scene />
  );
}

export default App;
