import React, { useState, useCallback } from 'react'
import { useBox } from "@react-three/cannon";
import { Text, RoundedBox } from "@react-three/drei";
import { useWeb3React } from "@web3-react/core";
import { injected } from "../connectors";


function ConnectMetamask(props) {
  const { active1, account, chainId, library, connector, activate } = useWeb3React();
  // This reference will give us direct access to the mesh
  const [ref] = useBox(() => ({ type: "Static", ...props }))
  const [hover, setHover] = useState(false)

  const onClick = useCallback((e) => {
    e.stopPropagation();

    activate(injected)
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
  }, [])

  const onPointerMove = useCallback((e) => {
    e.stopPropagation();
    setHover(true);
  }, [])

  const onPointerOut = useCallback((e) => {
    setHover(null)
  }, [])

  return (
    <mesh
      receiveShadow castShadow
      ref={ref}
      onClick={onClick}
      onPointerMove={onPointerMove}
      onPointerOut={onPointerOut}
    >
      <RoundedBox args={[1.7, 0.6, 0.6]} radius={0.3} smoothness={4}>
        <meshPhongMaterial attach="material" color={hover ? "#FFF" : "#DC143C"} />
      </RoundedBox>
      <Text
        scale={[2,2,2]}
        color="#111"
        anchorX="center"
        anchorY="bottom"
        position-y={0.5}
        position-z={0.5}
      >

        {typeof account !== "string" && `Connect To Metamask`}
        {typeof account === "string" && `Connected ${account}`}
      </Text>
      {typeof account === "string" &&
        <Text
          scale={[2,2,2]}
          color="#111"
          anchorX="center"
          anchorY="bottom"
          position-y={0.0}
          position-z={0.5}
        >
          Find the door
        </Text>
      }
    </mesh>
  );
}

export default ConnectMetamask;