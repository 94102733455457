import { LinearFilter } from 'three';
import React, { useState } from 'react';
import { Physics } from "@react-three/cannon";
import { Canvas, useResource } from '@react-three/fiber'
import { Sky, PointerLockControls, useTexture, useAspect } from "@react-three/drei";
import ConnectArbitrum from '../components/ConnectArbitrum';
import ConnectMetamask from '../components/ConnectMetamask';
import Player from "../components/Player";
import Ground from "../components/Ground";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";

function getLibrary(provider) {
  return new Web3Provider(provider);
}

const VRScene = () => {
  return (
    <Canvas id="canvas"
      shadows
      gl={{ alpha: false}}
      camera={{ fov: 45 }}
      raycaster={{
        computeOffsets: (e) => ({ offsetX: e.target.width / 2, offsetY: e.target.height / 2 }),
      }}
    >
      <Web3ReactProvider getLibrary={getLibrary}>
        <Sky sunPosition={[100, 20, 100]} />
        <ambientLight intensity={0.3} />
        <pointLight castShadow intensity={0.8} position={[100, 100, 100]} />
        <Physics gravity={[0, -20, 0]}>
          <Ground />
          <Player />
          <ConnectArbitrum position={[-2, 1, -10]} />
          <ConnectMetamask position={[2, 1, -10]} />
        </Physics>
        <PointerLockControls selector="#canvas" />      
      </Web3ReactProvider>
    </Canvas>
  );
}

export default VRScene;