import React, { useState, useCallback } from 'react'
import { useBox } from "@react-three/cannon";
import { Text, RoundedBox } from "@react-three/drei";
import { addArbitrumRPC } from "../hooks";
import { useWeb3React } from "@web3-react/core";


function ConnectArbitrum(props) {
  const { active, account, chainId, library, connector, activate } = useWeb3React();
  // This reference will give us direct access to the mesh
  const [ref] = useBox(() => ({ type: "Static", ...props }))
  const [hover, setHover] = useState(false)

  const onClick = useCallback((e) => {
    e.stopPropagation();
    addArbitrumRPC(library);
  }, [])

  const onPointerMove = useCallback((e) => {
    e.stopPropagation();
    setHover(true);
  }, [])

  const onPointerOut = useCallback((e) => {
    setHover(null)
  }, [])

  if (chainId === 42161) {
    return null;
  }
  return (
    <mesh
      receiveShadow castShadow
      ref={ref}
      onClick={onClick}
      onPointerMove={onPointerMove}
      onPointerOut={onPointerOut}
    >
      <RoundedBox args={[1.7, 0.6, 0.6]} radius={0.3} smoothness={4}>
        <meshPhongMaterial attach="material" color={hover ? "#FFF" : "#3db2ff"} />
      </RoundedBox>
      <Text
        scale={[2,2,2]}
        color="#111"
        anchorX="center"
        anchorY="bottom"
        position-y={0.5}
        position-z={0.5}
      >
        Connect To Arbitrum
      </Text>
    </mesh>
  );
}

export default ConnectArbitrum;